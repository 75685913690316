import React from "react"
import { Link } from "gatsby"

import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import "react-tabs/style/react-tabs.css"

import Layout from "../../components/de/layout"
//import Image from "../components/image"
import SEO from "../../components/de/seo"

import MainImg from "./../../images/main_bg.jpg"

import shape1 from "./../../images/dec/1.png"
import shape2 from "./../../images/dec/2.png"
import shape3 from "./../../images/dec/3.png"
import shape4 from "./../../images/dec/4.png"
import shape5 from "./../../images/dec/5.png"
import shape6 from "./../../images/dec/6.png"
import shape7 from "./../../images/dec/7.png"
import shape8 from "./../../images/dec/8.png"

import product4 from "./../../images/moonamar-hautbalsam-mit-argan-schwarzkummel-jojoba.png"

const CorokotovoUlje = () => (
  <Layout>
    <SEO
      title="MOONAMAR - HAUTBALSAM MIT ARGAN, SCHWARZKÜMMEL, JOJOBA"
      description="MOONAMAR Balsam mit Schwarzkümmel, Argan, Jojoba, Avocado & Sheabutter als reichhaltige Pflege trockener und empfindlicher Haut."
    />

    <section
      id="_heroPage"
      className="hero is-large"
      style={{ backgroundImage: `url(${MainImg})` }}
    >
      <div id="bgHero">
        <span className="shape1">
          <img src={shape1} alt="Shape 1" />
        </span>
        <span className="shape2">
          <img src={shape2} alt="Shape 2" />
        </span>
        <span className="shape3">
          <img src={shape3} alt="Shape 3" />
        </span>
        <span className="shape4">
          <img src={shape4} alt="Shape 4" />
        </span>
        <span className="shape5">
          <img src={shape5} alt="Shape 5" />
        </span>
        <span className="shape6">
          <img src={shape6} alt="Shape 6" />
        </span>
        <span className="shape7">
          <img src={shape7} alt="Shape 7" />
        </span>
        <span className="shape8">
          <img src={shape2} alt="Shape 8" />
        </span>
      </div>
      <div className="hero-body" />
    </section>

    <span className="_line" />

    <section id="_oProizvodu" className="section">
      <div className="container">
        <div className="columns">
          <div className="column">
            <div className="_slide">
              <img
                className="_img"
                src={product4}
                alt="HAUTBALSAM MIT ARGAN, SCHWARZKÜMMEL, JOJOBA"
                title="HAUTBALSAM MIT ARGAN, SCHWARZKÜMMEL, JOJOBA"
              />
              <div className="_info">
                <div className="_inner">
                  <span>MOONAMAR</span>
                  <div className="title">
                    Hautbalsam mit Schwarzkümmel, Argan, Jojoba, Avocado &
                    Sheabutter
                  </div>
                  <div className="subtitle">Verpackungsgröße: 50 ml</div>
                  <div className="price">8,90 €</div>
                </div>
                <div className="_buttonShop">
                  <Link to="/bestellformular">ZUR BESTELLUNG</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="_pageContent" className="section">
      <span className="_dec2">
        <img src={shape8} alt="Dec 2" />
      </span>
      <div className="container">
        <div className="columns">
          <div className="column is-8 is-offset-2">
            <Tabs>
              <TabList>
                <Tab>Produktbeschreibung</Tab>
                <Tab>Zutaten und Verwendung</Tab>
              </TabList>

              <TabPanel>
                <div className="content">
                  <p>
                    MOONAMAR naturreiner Balsam eignet sich als sehr
                    reichhaltige, beruhigende Pflege für trockene, empfindliche
                    und irritierte Haut, versorgt die Haut mit ausreichend
                    Feuchtigkeit, schützt sie vor Austrocknung und stärkt ihre
                    natürliche Regeneration. Er bewahrt den natürlichen
                    Feuchtigkeitshaushalt der Haut, wodurch das unbehagliche
                    Spannungsgefühl oder das Hautjucken gemildert wird.
                  </p>
                  <p>
                    <strong>- Auch für Babys geeignet</strong>
                    <br />
                    <strong>- Ohne zugesetzte Duftstoffe</strong>
                  </p>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="content">
                  <h3>Inhaltsstoffe:</h3>
                  <p>
                    Nigella Sativa (Schwarzkümmelöl), Persea gratissima
                    (Avocadoöl), Cera Alba (Bienenwachs), Argania Spinosa (Argan
                    Öl), Buxus Chinensis (Jojobaöl), Butyrospermum parkii
                    (Sheabutter).
                  </p>
                  <h3>Anwendung:</h3>
                  <p>
                    Hautbalsam kann sowohl für die Haut als auch bei spröden
                    Lippen oder Mundwinkeln angewendet werden. Empfehlung: pur
                    und dünn auf die zu behandelnden Hautstellen auftragen. Bei
                    trockenen Händen, Hautbalsam auftragen und über Nacht, mit
                    Baumwollhandschuhe geschützt, einwirken lassen.
                  </p>
                  <p>
                    <strong>
                      Der Balsam ist sowohl für Erwachsene als auch für Babys
                      und Kleinkinder geeignet.
                    </strong>
                  </p>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
      <span className="_dec3">
        <img src={shape6} alt="Dec 3" />
      </span>
    </section>
  </Layout>
)

export default CorokotovoUlje
